<template>
  <div>
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle">
      <template slot="button" v-if="!isCompany">
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('TeacherCourses.Create')"
          placement="top"
        >
          <router-link :to="CreateLink">
            <el-button size="small" type="success">
              <i class="fa fa-plus"></i>
            </el-button>
          </router-link>
        </el-tooltip>
      </template>
    </Heading>
    <small>
      You can manage all your published {{ myTitle }} and drafts here.
    </small>
    <hr />
    <el-form
      v-if="searchTest.isNew > -1 && searchTest.type"
      class="mt-4"
      :model="searchTest"
      label-position="right"
      label-width="80px"
      @submit.native.prevent
    >
      <el-form-item v-if="isTeacher" label="Source">
        <!-- <el-radio-group v-model="is_mine" @change="search">
          <el-radio-button :label="0">
            Official
          </el-radio-button>
          <el-radio-button label="2">
            SATMocks
          </el-radio-button>
        </el-radio-group> -->
        <div class="d-flex">
          <div>
            <el-radio-group v-model="searchTest.source">
              <el-radio-button :label="3">
                Company
              </el-radio-button>
              <el-radio-button :label="1">
                Official
              </el-radio-button>
              <el-radio-button :label="2">
                SATMocks
              </el-radio-button>
            </el-radio-group>
          </div>
        </div>
      </el-form-item>
      <el-form-item v-if="isCompany" label="Source">
        <div class="d-flex">
          <div>
            <el-radio-group v-model="searchTest.source">
              <!-- <el-radio-button :label="3">
                Company
              </el-radio-button> -->
              <el-radio-button :label="1">
                Official
              </el-radio-button>
              <el-radio-button :label="2">
                SATMocks
              </el-radio-button>
            </el-radio-group>
          </div>
        </div>
      </el-form-item>
      <!-- <el-form-item v-if="isAdmin" label="Version" prop="isNew">
        <el-radio-group
          v-model="searchTest.isNew"
          @change="search('changeNew')"
        >
          <el-radio-button :label="1">
            New Digital SAT
          </el-radio-button>
          <el-radio-button :label="0">
            Old Paper-Based SAT
          </el-radio-button>
        </el-radio-group>
      </el-form-item> -->
      <el-form-item v-if="isAdmin" label="Type" prop="type">
        <el-radio-group v-model="searchTest.type" @change="search">
          <el-radio-button label="composePractice">
            {{ $t(`sat.Full Tests`) }}
          </el-radio-button>
          <el-radio-button label="practice">
            {{ $t(`sat.Partial Tests`) }}
          </el-radio-button>
          <el-radio-button label="drill">
            {{ $t(`sat.Drill Practice`) }}
          </el-radio-button>
          <!-- <el-radio-button label="adaptive">
            {{ $t("sat.Adaptive Tests") }}
          </el-radio-button> -->
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="searchTest.type === 'practice' || searchTest.type === 'drill'"
        label="Subject"
      >
        <el-radio-group v-model="searchTest.subject" @change="search">
          <el-radio-button :label="null">
            All
          </el-radio-button>
          <el-radio-button
            v-for="subject in subjectsNow"
            :key="subject.id"
            :label="subject.id"
          >
            {{ titleCase(subject.name) }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Status" prop="type" v-show="!isTeacher || is_mine">
        <el-radio-group v-model="searchTest.is_public" @change="search">
          <el-radio-button :label="1">
            Published
          </el-radio-button>
          <el-radio-button :label="0">
            Drafts
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Keywords">
        <el-input
          ref="filterInput"
          placeholder="Name"
          v-model="searchTest.search"
          @keyup.enter.native="search"
        >
          <el-button
            el-button
            slot="append"
            icon="el-icon-search"
            @click="search"
          >
            Search
          </el-button>
        </el-input>
      </el-form-item>
      <!-- <el-form-item label="發佈狀態">
      </el-form-item> -->
    </el-form>
    <template v-if="practices">
      <hr />
      <el-alert v-if="resultsTotal > -1" type="success" :closable="false">
        <span slot="title">
          Results: <b>{{ resultsTotal }}</b>
        </span>
      </el-alert>
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
      <el-table
        v-if="practices.length > 0"
        :data="practices"
        style="width: 100%"
        stripe
      >
        <el-table-column label="Name">
          <template slot-scope="scope">
            <router-link
              :to="{
                name:
                  searchTest.type === 'composePractice'
                    ? 'SatComposePracticeDetailAll'
                    : 'SatPracticeDetailAll',
                query: { id: scope.row.id }
              }"
            >
              <b>{{ scope.row.exam.title }}</b>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
          label="Subjects"
          width="100"
          v-if="searchTest.type === 'composePractice'"
        >
          <template slot-scope="scope">
            <b class="text-success">
              {{ scope.row.practices.length }}
            </b>
          </template>
        </el-table-column>
        <el-table-column label="Questions" width="100">
          <template slot-scope="scope">
            <template
              class="text-success"
              v-if="searchTest.type === 'composePractice'"
            >
              <b class="text-success">
                {{ getQuestionsCount(scope.row.practices) }}
              </b>
            </template>
            <template v-else>
              <b class="text-success">{{
                scope.row.exam.exam_questions_count
              }}</b>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          label="Score Table"
          width="100"
          v-if="
            searchTest.type === 'composePractice' && (!isTeacher || is_mine)
          "
        >
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.scorable_id && scope.row.scorable_id > 0"
              class="mr-2"
              effect="dark"
              content="View Score Table"
              placement="top"
            >
              <router-link
                :to="{
                  name: 'ScoreTable',
                  params: {
                    type: searchTest.type,
                    practiceId: scope.row.id,
                    id: scope.row.scorable_id
                  }
                }"
              >
                <el-button type="success" size="mini">
                  <i class="fas fa-table"></i>
                </el-button>
              </router-link>
            </el-tooltip>
            <el-tooltip
              v-else
              class="mr-2"
              effect="dark"
              content="No Score Table"
              placement="top"
            >
              <router-link
                :to="{
                  name: 'CreateScoreTable',
                  params: {
                    type: searchTest.type,
                    practiceId: scope.row.id
                  }
                }"
              >
                <el-button type="danger" size="mini">
                  <i class="fas fa-exclamation-triangle"></i>
                </el-button>
              </router-link>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column v-if="isAdmin" label="Students" width="100">
          <template slot-scope="scope">
            <el-button
              v-if="teacher"
              type="success"
              size="mini"
              style="width: 4rem;"
            >
              <i class="fas fa-users"></i>
              {{ scope.row.exam.user_exams_count }}
            </el-button>
            <router-link
              v-else
              :to="{
                name: 'ResultsByExams',
                params: {
                  type: searchTest.type,
                  examId: scope.row.exam.id
                }
              }"
            >
              <el-button type="success" size="mini" style="width: 4rem;">
                <i class="fas fa-users"></i>
                {{ scope.row.exam.user_exams_count }}
              </el-button>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column label="Date Created" width="150">
          <template slot-scope="scope">
            {{ instant.formatGoogleTime(scope.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column width="350" label="Actions" fixed="right">
          <template slot-scope="scope">
            <!-- <el-tooltip
              class="mr-2"
              effect="dark"
              content="Start a test"
              placement="top"
              v-if="scope.row.exam.is_public && scope.row.exam.is_public === 1"
            >
              <router-link
                :to="{
                  name: isPhone ? 'SATMobileTest' : 'SATTest',
                  params: {
                    practiceType:
                      searchTest.type === 'composePractice'
                        ? 'composePractices'
                        : 'singlePractice',
                    id: scope.row.id
                  }
                }"
              >
                <el-button type="success" size="mini">
                  <i class="fas fa-play"></i>
                </el-button>
              </router-link>
            </el-tooltip> -->
            <el-tooltip
              class="mr-2"
              effect="dark"
              content="Preview"
              placement="top"
            >
              <router-link
                :to="{
                  name:
                    searchTest.type === 'composePractice'
                      ? 'SatComposePracticeDetailAll'
                      : 'SatPracticeDetailAll',
                  query: { id: scope.row.id }
                }"
              >
                <el-button type="success" size="mini">
                  <i class="fas fa-eye"></i>
                </el-button>
              </router-link>
            </el-tooltip>
            <template v-if="isAdmin || is_mine">
              <el-tooltip
                class="mr-2"
                effect="dark"
                content="Regrade all (for after you edit your questions)"
                placement="top"
              >
                <el-button
                  @click="reGrading(scope.row.exam.id)"
                  type="warning"
                  size="mini"
                >
                  <i class="fas fa-redo"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip
                v-if="searchTest.type !== 'composePractice'"
                class="mr-2"
                effect="dark"
                content="Duplicate"
                placement="top"
              >
                <el-button
                  class="m-0"
                  @click="copyPractice(searchTest.type, scope.row.id)"
                  type="warning"
                  size="mini"
                >
                  <i class="fas fa-copy"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip
                class="mr-2"
                effect="dark"
                content="Edit"
                placement="top"
              >
                <router-link
                  :to="{
                    name:
                      searchTest.type === 'composePractice'
                        ? 'SatComposePracticeDetail'
                        : 'SatPracticeDetail',
                    query: { id: scope.row.id, isEdit: 1 }
                  }"
                >
                  <el-button type="warning" size="mini">
                    <i class="fa fa-edit"></i>
                  </el-button>
                </router-link>
              </el-tooltip>
              <el-tooltip
                class="mr-2"
                effect="dark"
                content="Change to draft"
                placement="top"
                v-if="
                  scope.row.exam.is_public && scope.row.exam.is_public === 1
                "
              >
                <el-button
                  type="danger"
                  size="mini"
                  @click="updatePublic(scope.row.id, scope.row.exam.title, 0)"
                >
                  <i class="fas fa-level-down-alt"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                content="Publish"
                placement="top"
                v-if="
                  scope.row.exam.is_public > -1 &&
                    scope.row.exam.is_public === 0 &&
                    ((searchTest.type == 'composePractice' &&
                      scope.row.scorable_id &&
                      scope.row.scorable_id > 0) ||
                      searchTest.type !== 'composePractice')
                "
              >
                <el-button
                  type="success"
                  size="mini"
                  @click="updatePublic(scope.row.id, scope.row.exam.title, 1)"
                >
                  <i class="fas fa-upload"></i>
                </el-button>
              </el-tooltip>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <div v-else>
        <el-empty :description="$t('courses.empty-text')"></el-empty>
      </div>
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </template>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import { instant } from "@ivy-way/material";
import SAT from "@/apis/sat.js";
import Common from "@/mixins/common.js";
import Theme from "@/common/theme";
import Company from "@/apis/company";

export default {
  metaInfo() {
    return {
      title: "SAT Practices - " + this.CompanyName
    };
  },

  components: { Breadcrumb },

  mixins: [Common],

  props: [],
  data() {
    return {
      searchTest: {
        isNew: null,
        type: null,
        subject: null,
        is_public: null,
        search: null,
        source: 3
      },
      practices: null,
      resultsTotal: 0,
      pageSize: 0,
      subjects: [],
      companies: []
      // composePractices: null
    };
  },
  computed: {
    is_mine() {
      return this.searchTest.source === 3;
    },
    ...mapState("user", ["profile"]),
    CreateLink() {
      let Link = "";
      if (this.searchTest.type === "drill") {
        if (this.isAdmin) {
          Link = {
            name: "SatAddPractice",
            query: { type: "drill" }
          };
        }
        if (this.isTeacher) {
          Link = {
            name: "TeacherSatAddPractice",
            query: { type: "drill" }
          };
        }
      } else if (this.searchTest.type === "practice") {
        Link = {
          name: "SatAddPractice"
        };
      } else if (this.searchTest.type === "adaptive") {
        Link = {
          name: "SatAddAdaptive"
        };
      } else if (this.searchTest.type === "composePractice") {
        Link = {
          name: "SatAddComposePractice"
        };
      }
      return Link;
    },
    myTitle() {
      let myTitle = "";
      if (this.searchTest.type === "drill") {
        myTitle = this.$t("sat.Drill Practice");
      } else if (this.searchTest.type === "adaptive") {
        myTitle = this.$t("Adaptive Tests");
      } else if (this.searchTest.type === "practice") {
        myTitle = this.$t("sat.Partial Tests");
      } else if (this.searchTest.type === "composePractice") {
        myTitle = this.$t("sat.Full Tests");
      }
      return myTitle;
    },
    instant() {
      return instant;
    },
    isTeacher() {
      return this.$route.name === "TeacherSatManagement";
    },
    isCompany() {
      return this.$route.name === "CompanySatManagement";
    },
    isAdmin() {
      return this.$route.name === "SatManagement";
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    subjectsNow() {
      let subjects = [];
      // if (this.searchTest.isNew === 1) {
      //   subjects = [this.subjects[4], this.subjects[2]];
      // } else {
      //   subjects = [
      //     this.subjects[0],
      //     this.subjects[1],
      //     this.subjects[2],
      //     this.subjects[3]
      //   ];
      // }
      subjects = this.subjects;
      return subjects;
    },
    isNew() {
      let isNew = 1;
      if (this.$route.query.isNew === undefined) {
        isNew = 1;
      } else {
        isNew = parseInt(this.$route.query.isNew);
      }
      return isNew;
    },
    ...mapState("user", ["token", "lang"]),
    isLogin() {
      return this.token !== "";
    },
    subjectId() {
      let subjectId = null;
      if (this.$route.query.subject_id) {
        subjectId = this.$route.query.subject_id;
      }
      return subjectId;
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    },
    theme() {
      return Theme.name;
    }
  },
  watch: {},

  async mounted() {
    this.searchTest.is_public =
      Number(this.$route.query.is_public) > -1
        ? Number(this.$route.query.is_public)
        : 1;
    this.searchTest.isNew =
      Number(this.$route.query.isNew) > -1
        ? Number(this.$route.query.isNew)
        : 1;

    this.searchTest.search = this.$route.query.search
      ? this.$route.query.search
      : null;
    this.searchTest.source =
      Number(this.$route.query.source) > -1
        ? Number(this.$route.query.source)
        : 3;
    this.searchTest.type = this.$route.query.type
      ? this.$route.query.type
      : "composePractice";
    this.searchTest.subject = this.$route.query.subject
      ? this.$route.query.subject
      : null;
    if (this.isLogin) {
      const subjects = await SAT.getSubjects();
      this.subjects = subjects.sat_subjects;
      this.getData();
    } else {
      if (this.theme === "SAT") {
        this.$router.push({ name: "Login" });
      }
    }
    this.getCompanies();
  },

  methods: {
    async getCompanies() {
      if (this.isAdmin) {
        const res = await Company.listCompany();
        this.companies = res.companies.data;
      } else if (this.isTeacher) {
        const res = await Company.listCompany();
        this.companies = res.companies.data;
      } else {
        const res = await Company.listCompany();
        this.companies = res.companies.data;
      }
    },
    reGrading(examId) {
      this.$confirm(
        this.$t("management.Are you sure you want to regrade all tests?"),
        this.$t("management.Regrade"),
        {
          confirmButtonText: this.$t("alert.confirm"),
          cancelButtonText: this.$t("alert.cancel"),
          type: "warning"
        }
      )
        .then(async () => {
          await SAT.reGrading(examId);
          this.$message({
            message: "Success!",
            type: "success"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("alert.Canceled")
          });
        });
    },
    async copyPractice(type, id) {
      if (type === "composePractice") {
        await SAT.copyComposePractice(id);
      } else {
        await SAT.copyPractice(id);
      }
      await this.getData();
      this.$message({
        message: this.$t("management.Duplicate created"),
        type: "success"
      });
    },
    getQuestionsCount(practices) {
      let count = 0;
      practices.forEach(practice => {
        count += practice.exam.exam_questions_count;
      });
      return count;
    },
    async getData() {
      let res = null;
      let edit_user_id = null;
      if (this.is_mine) {
        edit_user_id = this.profile.id;
      }
      if (this.searchTest.type === "composePractice") {
        res = await SAT.getComposePractices({
          // edit_user_id,
          is_new: this.searchTest.isNew,
          page: this.page,
          per_page: 10,
          is_public: this.searchTest.is_public,
          keyword: this.searchTest.search
        });
        this.practices = res.compose_practice.data;
        this.resultsTotal = res.compose_practice.total;
        this.pageSize = res.compose_practice.per_page;
      } else if (
        this.searchTest.type === "practice" ||
        this.searchTest.type === "drill"
      ) {
        res = await SAT.getPractices({
          // edit_user_id,
          // subject_type: "Modules\\SAT\\Entities\\SatSubject",
          // subject_id: this.searchTest.subject,
          // is_new: this.searchTest.isNew,
          is_new: 0,
          page: this.page,
          per_page: 10,
          is_public: this.searchTest.is_public,
          type: this.searchTest.type === "drill" ? "drill" : null,
          keyword: this.searchTest.search
        });
        this.practices = res.practices.data;
        this.resultsTotal = res.practices.total;
        this.pageSize = res.practices.per_page;
      }
    },
    async updatePublic(id, title, is_public) {
      if (
        this.searchTest.type === "practice" ||
        this.searchTest.type === "drill"
      ) {
        await SAT.updatePractice(id, {
          practice: {
            exam: {
              title: title,
              is_public: is_public
            }
          }
        });
      } else if (this.searchTest.type === "composePractice") {
        await SAT.updateComposePractice(id, {
          compose_practice: {
            exam: {
              title: title,
              is_public: is_public
            }
          }
        });
      }
      if (is_public === 1) {
        await this.$message({
          message: this.$t("management.Published!"),
          type: "success"
        });
      } else {
        await this.$message(this.$t("management.Moved to Drafts"));
      }
      this.getData();
    },
    async search(type) {
      if (type === "changeNew") {
        this.searchTest.subject = null;
      }
      this.$router.replace({
        params: {
          ...this.$route.params
        },
        query: {
          ...this.$route.query,
          ...this.searchTest,
          page: 1
        }
      });
    },
    setPage(page) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    }
  }
};
</script>

<style scoped></style>
